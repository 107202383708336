@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap");

.skills_card {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 5px;
  width: 30vw;
  height: 80%;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #0d0d0d;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
  z-index: 1;
  margin-bottom: 20px;
}

.skills_card h2 {
  font-family: var(--primary-ff);
  font-size: 2rem;
  font-weight: bold;
}

.skills_card::before {
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 5px;
  content: "";
  top: 0;
  left: 0;
  background-color: var(--primary-clr);
}

.skills_card h3 {
  font-family: var(--primary-ff);
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
}

.skills_container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: 90%;
}

.skill_item {
  display: flex;
  width: 3rem;
  height: 3rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 40px;
}

.skill_img {
  color: var(--primary-clr);
  font-size: 2.8rem;
}

@media only screen and (max-width: 900px) {
  .skills_card {
    width: 100%;
  }
}
