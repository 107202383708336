@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap");

:root {
  --test-clr: #bc03ff;
}

.about_body-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: +1;
  background: linear-gradient(
    180deg,
    rgba(12, 12, 12, 1) 0%,
    rgba(12, 12, 12, 1) 70%,
    rgba(0, 34, 56, 0) 100%
  );
}

.about_body-container h1 {
  font-family: var(--primary-ff);
  font-size: 5rem;
  font-weight: bold;
  margin-left: 10vw;
  margin-right: auto;
  margin-top: 100px;
}

.about_content-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.about_text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 40%;
  margin-left: 10vw;
  padding-right: 1vw;
}

.about_text-container h1 {
  font-family: var(--primary-ff);
  font-size: 3rem;
  font-weight: bold;
  color: var(--secondary-clr);
  margin: 0;
  margin-bottom: 50px;
}

.about_card h2,
.about_card h3,
.about_card h4,
.about_card span {
  font-family: var(--primary-ff);
  font-size: 1.25rem;
  font-weight: 400;
  margin: 0;
  text-align: start;
  background-color: #0d0d0d;
}

.about_card {
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: #0d0d0d;
  border-radius: 10px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.about_card::before {
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 5px;
  content: "";
  top: 0;
  left: 0;
  background-color: var(--primary-clr);
}

.about_card h2 {
  line-height: 1.6;
  margin-bottom: 20px;
}

.about_text-container h3 {
  margin-top: 50px;
}

.about_text-container h4 {
  margin-top: 20px;
}

.about_text-container span {
  font-weight: bold;
  font-size: 1.6rem;
  margin: 0;
  margin-right: 10px;
  color: var(--tertiary-clr);
}

@media only screen and (max-width: 900px) {
  .about_body-container {
    padding-left: 80px;
    padding-right: 80px;
  }

  .about_body-container h1 {
    margin-left: 0;
  }
  .about_content-container {
    flex-direction: column;
  }

  .about_text-container {
    width: 100%;
    margin-left: 0;
  }
}

@media only screen and (max-width: 600px) {
  .about_body-container {
    padding-left: 50px;
    padding-right: 50px;
  }

  .about_body-container h1 {
    font-size: 16vw;
  }

  .about_content-container h1 {
    font-size: 10vw;
  }

  .about_card h2 {
    font-size: 0.95rem;
  }

  .about_card h3 {
    font-size: 0.95rem;
  }

  .about_card h4 {
    font-size: 0.95rem;
    line-height: 1.6;
  }
}
