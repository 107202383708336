.skills_body-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: +1;
  margin-right: 10vw;
}

.skills_body-container h1 {
  font-family: var(--primary-ff);
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
  margin-bottom: 50px;
  color: white;
}

.skills_display-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
  align-items: flex-start;
}

@media only screen and (max-width: 900px) {
  .skills_body-container {
    margin-top: 50px;
    margin-right: 0;
  }
}
