@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap");

/* :root {
  --primary-ff: "Inter", sans-serif;
  --primary-clr: #6a00f4;
  --secondary-clr: #8023fa;
  --tertiary-clr: #913dff;
  --secondary-main-clr: #ffdb26;
  --body-btm-margin: 10px;
  --body-role-margin: 60px;
} */

.project_body_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-bottom: 80px;
  justify-content: center;
  padding: 20px;
  /* background-color: rgb(20, 20, 20);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px; */
}

.app_mockup-container {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}

.app_mockup-overlay {
  position: relative;
  display: inline-block;
}

.app_mockup-overlay::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(128, 255, 206);
  background: linear-gradient(
    29deg,
    rgba(0, 219, 110, 0.4) 15%,
    rgba(0, 255, 98, 0.4) 100%
  );
  z-index: 2;
  border-radius: 5px;
}

.app_mockup {
  width: 40vw;
  border-radius: 5px;
  filter: grayscale(0.8);
}

.project_desc_container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  --height-max: 90%;
}

.project_desc_container h1 {
  font-family: var(--primary-ff);
  font-weight: 500;
  font-size: 5rem;
  margin: 0;
  margin-top: 30px;
  margin-bottom: 50px;
  z-index: 3;
}

.project_desc_container h1 span {
  font-family: var(--primary-ff);
  font-weight: bold;
  text-decoration: none;
  font-size: 5rem;
  color: var(--secondary-clr);
  z-index: 3;
}

.project_desc_container h2 {
  font-family: var(--primary-ff);
  font-weight: 400;
  font-size: 1.5rem;
  text-align: start;
  width: 50%;
  margin-bottom: 0;
  padding: 15px;
  border-radius: 5px;
  background-color: rgb(17, 17, 17);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  z-index: 3;
}

.project_desc_container h3 {
  position: relative;
  font-family: var(--primary-ff);
  font-weight: 400;
  font-size: 1.2rem;
  text-align: start;
  width: 40%;
  margin-top: var(--body-role-margin);
  margin-bottom: var(--body-role-margin);
  z-index: 3;
  margin-left: 15px;
}

.project_desc_container h3 span {
  font-family: var(--primary-ff);
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: var(--body-btm-margin);
  z-index: 3;
}

.project_desc_container h3::before {
  display: inline-block;
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  background-color: white;
  top: -20px;
  left: 0;
  z-index: 3;
}

.project_desc_container h3::after {
  display: inline-block;
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  background-color: white;
  bottom: -20px;
  right: 0;
  z-index: 3;
}

.project_desc_container h4 {
  font-family: var(--primary-ff);
  font-weight: 600;
  font-size: 1.2rem;
  margin-top: 0;
  margin-bottom: var(--body-btm-margin);
  z-index: 3;
  margin-left: 15px;
}

.project_stack_container {
  display: flex;
  position: relative;
  align-items: center;
  height: 100%;
  width: 40%;
  flex-wrap: wrap;
  justify-content: baseline;
  z-index: 3;
  margin-left: 15px;
}

.project_stack_container div {
  border-style: solid;
  border-color: var(--tertiary-clr);
  color: var(--tertiary-clr);
  border-radius: 8px;
  border-width: 2px;
  margin-right: 10px;
  margin-bottom: 8px;
  padding: 5px;
  font-family: var(--primary-ff);
  font-weight: 600;
  font-size: 1rem;
}

.project_button_container {
  display: flex;
  position: relative;
  margin-top: 50px;
  z-index: 5;
  margin-left: 15px;
}

.project_demo_btn,
.project_github_btn {
  font-family: var(--primary-ff);
  font-weight: bold;
  font-size: 1.3rem;
  border-radius: 2px;
  border-style: none;
  width: 150px;
  height: 60px;
  margin-right: 20px;
  text-decoration: none;
  text-align: center;
  display: block;
  transition: 0.4s ease;
  cursor: pointer;
}

.project_demo_btn:hover {
  width: calc(150px + 40px);
}

.project_github_btn:hover {
  width: calc(170px + 40px);
}

.project_demo_btn {
  background-color: var(--primary-clr);
  color: black;
}

.project_github_btn {
  background-color: rgb(36, 36, 36);
  color: white;
  width: 170px;
}

@media only screen and (max-width: 1100px) {
  .project_desc_container h2 {
    font-size: 1.2rem;
  }

  .project_desc_container h3,
  .project_desc_container h3 span {
    font-size: 1rem;
    margin-bottom: 30px;
  }

  .project_desc_container h3 {
    margin-top: 45px;
  }

  .project_desc_container h4 {
    font-size: 1.1rem;
    margin-top: 20px;
  }

  .project_stack_container div {
    font-size: 0.9rem;
  }

  .project_button_container {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 900px) {
  .project_body_container {
    margin: 10px;
    padding: 0;
  }
  .project_desc_container {
    justify-content: flex-start;
    align-items: flex-start;
    height: max-content;
    width: 100%;
    background: linear-gradient(
      65deg,
      rgb(49, 49, 49) 15%,
      rgb(20, 20, 20) 100%
    );

    border-radius: 5px;
    padding: 20px;
  }

  .app_mockup-container {
    width: 100%;
    height: 100%;
  }

  .app_mockup {
    height: auto;
    width: 100%;
    display: block;
    margin: auto;
    opacity: 0.2;
    filter: blur(2px) grayscale(0.8);
  }

  .app_mockup-overlay {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .app_mockup-overlay::after {
    display: none;
  }

  .project_desc_container h1,
  .project_desc_container h1 span {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 0;
  }

  .project_desc_container h2,
  .project_desc_container h3,
  .project_desc_container h4,
  .project_stack_container {
    font-size: 0.9rem;
    width: 100%;
  }

  .project_desc_container h2 {
    background-color: rgba(14, 14, 14, 0.603);
    backdrop-filter: saturate(180%) blur(10px);
    padding: 10px;
  }

  .project_desc_container h3,
  .project_desc_container h3::after {
    display: none;
  }

  .project_desc_container h4 {
    margin-top: 10px;
    margin-left: 0;
    color: rgb(214, 214, 214);
  }

  .project_stack_container {
    justify-content: center;
    height: max-content;
    width: 100%;
    margin-left: 0;
  }

  .project_stack_container div {
    font-size: 0.8rem;
    border: none;
    margin-bottom: 0;
    margin-right: 5px;
    padding: 3px;
    color: rgb(137, 255, 137);
  }

  .project_button_container {
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-left: 0;
    width: 100%;
  }

  .project_github_btn,
  .project_demo_btn {
    font-size: 1rem;
    height: 40px;
    margin: 10px;
    margin-bottom: 0;
    width: max-content;
    white-space: nowrap;
    padding: 6px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .project_github_btn:hover,
  .project_demo_btn:hover {
    padding-top: 0;
    padding: 6px;
    width: max-content;
  }

  .project_github_btn {
    background-color: rgb(20, 20, 20, 1);
  }
}
